import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout.jsx';
import Brands from '../components/Brands.jsx';
import Gifts from '../components/Gifts.jsx';

export default ({ data }) => {
  const { content } = data // data.markdownRemark holds our post data
  const { frontmatter } = content

  return (
    <Layout title="Home">
      <Brands brands={frontmatter.brands} />
      <Gifts {...content} {...frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
query($path: String!) {
  content: markdownRemark(frontmatter: { path: { eq: $path } }) {
    html
    frontmatter {
      title
      image
      cards {
        cta
        header
        src
        url
      }
      brands {
        name
        src
      }
    }
  }
}
`
