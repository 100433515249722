import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import css from './Brands.module.css';

class Brands extends React.Component {
  render() {
    const settings = {
      arrows: true,
      infinite: true,
      slidesToScroll: 1,
      swipeToSlide: true,
      variableWidth: true
    };

    return (
      <Slider {...settings}>
        {
          this.props.brands.map(brand => (
            <img
              alt={brand.name}
              className={css.image}
              key={brand.name}
              src={brand.src}
            />
          ))
        }
      </Slider>
    );
  }
};

export default Brands;
