import React from 'react';
import css from './Gifts.module.scss';

export default (props) =>
  <div className={css.wrapper}>
    <div className={css.content}>

      <div className={css.columns}>
        <img src={props.image} alt="AJ"/>
      </div>

      <div className={css.columns}>
        <div
          className={css.markdown}
          dangerouslySetInnerHTML={{ __html: props.html }}
        />

        <div className={css.gift}>
          {
            props.cards.map((card, i) => (
              <div className={css.giftCard} key={i}>
                <img src={card.src} alt=""/>
                <h3>{card.header}</h3>
                <a href={card.url} key={card.url}>{card.cta}</a>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  </div>
